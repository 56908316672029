<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-3">
        <button class="btn btn-primary" v-on:click="previous">
          <span class="fa fa-arrow-left"></span>
        </button>
      </div>
    </div>
    <div class="row" v-if="project.environments">
      <div class="col-lg-4" v-for="(environment) in project.environments" v-bind:key="environment">
          <ReleaseDescription :environment="environment.name" :deploys="deploys"></ReleaseDescription>
      </div>
    </div>
  </div>
</template>

<script>

import {api} from "../services/api";
import router from "../router";
import ReleaseDescription from "../components/ReleaseDescription";

export default {
  name: "Release.vue",
  components: {ReleaseDescription},
  methods:{
    previous() {
      router.back();
    },
    listener() {
      console.log("Listened")
      this.refreshDeploy();
    },
    async refreshDeploy() {
      this.deploys = await api.deploys.get(`all/${this.$route.params.id}`);
    },
    async refreshBuild() {
      this.build = await api.builds.get(this.$route.params.id);
    },
    showLogs(index){
      console.log(index)
    },
  },
  data() {
    return {
      project:{},
      deploys: [],
      build:{},
    }
  },
  async created() {
    await Promise.all([this.refreshDeploy(),this.refreshBuild()]);
    this.project = await api.projects.get(this.build.project_id);
    this.socket.on('notify',this.listener);
  }
}
</script>

<style scoped>

</style>