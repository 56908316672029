<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ environment }}</h5>
      <div class="mt-3 mb-3">
        <div v-if="deploy == null">
          <span class="fa fa-exclamation-circle text-primary"></span>
          <span> Ready to deploy</span>
        </div>
        <div v-else>
          <div v-if="deploy.status === 0">
            <span class="fa fa-check-circle text-primary" ></span>
            <span> Deployment Queue</span>
          </div>
          <div v-if="deploy.status === 1">
            <span class="fa fa-check-circle text-warning" ></span>
            <span> Deployment On Going</span>
          </div>
          <div v-if="deploy.status === 2">
            <span class="fa fa-check-circle text-success" ></span>
            <span> Deployment Success</span>
          </div>
          <div v-if="deploy.status === 3">
            <span class="fa fa-exclamation-circle text-danger" ></span>
            <span> Deployment Error</span>
          </div>
        </div>
      </div>
      <div v-if="deploy == null">
        <button class="btn-primary btn" v-on:click="deploying(environment)">
          <span class="fa fa-rocket"></span>
        </button>
      </div>
      <div v-else>
        <div v-if="deploy.status === 1">
          <button class="btn-warning btn" v-on:click="showLogs(deploy._id)">
            Logs
          </button>
        </div>
        <div v-if="deploy.status === 2">
          <button class="btn-success btn" v-on:click="showLogs(deploy._id)">
            Logs
          </button>
          <button class="btn-warning btn" v-on:click="next(environment,deploy._id)">
            Next Build
          </button>

          <button class="btn-primary btn" v-on:click="deploying(environment)">
            <span class="fa fa-rocket"></span>
          </button>
        </div>
        <div v-if="deploy.status === 3">
          <button class="btn-danger btn me-3" v-on:click="showLogs(deploy._id)">
            Logs
          </button>
          <button class="btn-primary btn" v-on:click="deploying(environment)">
            <span class="fa fa-rocket"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {status} from '../services/status.json'
import {api} from "../services/api";
import router from "../router";
export default {
  name: "ReleaseDescription",
  props:{
    deploys: Array,
    environment:String
  },
  methods:{
    async deploying(environment) {
      await api.deploys.get(`/deploy/${this.$route.params.id}/${environment}`);
    },
    showLogs(id){
        router.push(`/logs/${id}`);
    },
    async next(environment,id){
        await api.deploys.get(`/deploy/${this.$route.params.id}/${environment}/${id}`);
    }
  },
  data() {
    return {
      status: status,
    }
  },
  computed:{
    deploy() {
      return  this.deploys.find(d => d.environment === this.environment);
    }
  },
}
</script>

<style scoped>

</style>